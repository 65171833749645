import React from 'react';
import { Button } from 'react-bootstrap';

const Song = ({ song, index, onReject }) => {
    return (
        <li className="track__item" key={song.id}>
            <div className="track__item__title-group">
                {song.album_cover && (
                    <div className="track__item__img">
                        <img src={song.album_cover} alt={song.track} />
                    </div>
                )}
                <div className="track__item__name-artist">
                    <div className="track__item__name overflow-ellipsis">
                        {song.track}
                    </div>
                    <div className="track__item__artist overflow-ellipsis">
                        <span>
                            {song.artist}
                        </span>
                    </div>
                </div>
            </div>
            <div className="track__item__info">
                <div className="track__item__time overflow-ellipsis">
                    {song.created_at.toLocaleTimeString()}
                </div>
                <div className="track__item__time overflow-ellipsis">
                    Anonymous
                </div>
                {/* <Button 
                    onClick={() => onReject(index)} 
                    className="btn-reject btn-sm delete-button">
                    X
                </Button> */}
            </div>
        </li>
    );
};

export default Song;
