import React, { useState, useEffect } from "react";
import "./DJView.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Button } from 'react-bootstrap';
import { StyledTrackList } from '../styles';
import { initializeApp } from "firebase/app";
import {
    getFirestore, collection, getDocs, doc, deleteDoc,
    query, orderBy, onSnapshot, writeBatch
} from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import Song from '../Song';
import { useSwipeable } from "react-swipeable";


const SwipeableItem = ({ children, onDelete, index }) => {
    const [isSwiped, setIsSwiped] = useState(false);
  
    const handlers = useSwipeable({
      onSwiping: (eventData) => {
        if (eventData.dir === "Left" && eventData.absX > 30) {
          setIsSwiped(true);
        } else if (eventData.dir === "Right" && eventData.absX > 30) {
          setIsSwiped(false);
        }
      },
      onSwipedLeft: () => setIsSwiped(true),
      onSwipedRight: () => setIsSwiped(false),
    });
  
    return (
      <article className={`swipeable ${isSwiped ? "swiped" : ""}`}>
        <div className="inner" {...handlers}>
          {children}
        </div>
        <div className="btn-reject action-button delete" onClick={() => onDelete(index)} >
          <div className="big-text">X</div>
        </div>
      </article>
    );
  };

const DJView = () => {
    const [songs, setSongs] = useState([]);

    // Initialize Firebase app
    initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
    const db = getFirestore();
    const colRef = collection(db, 'songRecs');

    useEffect(() => {
        // Query to get documents ordered by `createdAt` field
        const q = query(colRef, orderBy('created_at', 'desc'));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const songs = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                // Check if created_at is a Firestore Timestamp and convert it to Date
                if (data.created_at instanceof Timestamp) {
                    data.created_at = data.created_at.toDate();
                }
                songs.push({...data, id: doc.id});
            });
            setSongs(songs);
            console.log("Current tracks: ", songs);
        });

        // Clean up listener on component unmount
        return () => unsubscribe();
    }, []);

    async function refresh() {
        const q = query(colRef, orderBy('created_at', 'desc'));
        const snapshot = await getDocs(q);
        const songs = [];
        snapshot.docs.forEach((doc) => {
            const data = doc.data();
            // Check if created_at is a Firestore Timestamp and convert it to Date
            if (data.created_at instanceof Timestamp) {
                data.created_at = data.created_at.toDate();
            }
            songs.push({...data, id: doc.id});
        });
        setSongs(songs);
        console.log("Current tracks: ", songs);
    }

    async function rejectSong(index) {
        const docRef = doc(db, 'songRecs', songs[index].id);
        await deleteDoc(docRef);
    }

    async function clearAll() {
        const batch = writeBatch(db);
        songs.forEach(song => {
            const docRef = doc(db, 'songRecs', song.id);
            batch.delete(docRef);
        });
        await batch.commit();
    }

    const groupSongsByDate = (songs) => {
        return songs.reduce((groups, song) => {
            const date = song.created_at.toDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(song);
            return groups;
        }, {});
    };

    const groupedSongs = groupSongsByDate(songs);

    return (
        <div className="DJView tracktext">
            <h1 className="song-req-title">Requested songs:</h1>
            <Button onClick={refresh} 
                    className="refresh-btn btn-spotify btn-sm">
                Refresh
            </Button>
            <Button onClick={clearAll} 
                    className="btn-reject btn-sm">
                Clear All
            </Button>
            {Object.keys(groupedSongs).length > 0 ? (
                <StyledTrackList>
                    {Object.keys(groupedSongs).map((date) => (
                        <div key={date}>
                            <div className="date-separator">
                                <span>{date}</span>
                            </div>
                            {groupedSongs[date].map((song, index) => (
                                <SwipeableItem onDelete={rejectSong} index={index}>
                                    <Song
                                        key={song.id}
                                        song={song}
                                        index={index}
                                        onReject={rejectSong}
                                    />
                                </SwipeableItem>
                            ))}
                        </div>
                    ))}
                </StyledTrackList>
            ) : <h3>No song recs yet</h3>}
        </div>
    );
};

export default DJView;
