import React from "react";
import "./Hero.css";
import logo from './Arms.png'; 
import caret from './white-down.png'; 
import heart from './heart.png'; 
import {Link} from 'react-scroll';

const Hero = () => {
    return(
        <div className="Hero">
            <div className="move-down d-flex flex-sm-row flex-column flex-wrap p-2 justify-content-center align-items-center">
                {/* <div className="p-2 text-center move-up">
                    <p className="built-by"> Made with <img className="heart" src={heart} alt={"Heart"} /> by AEPi Social Chairs </p>
                </div> */}
                {/*Should I add a "Jewkbox" title?*/}
                <div className="flex-shrink-0 p-2">
                    <img src={logo} className="arms" alt={"Coat of arms"} />
                </div>
                <div className="p-2 text-center">
                    <h1 className="welcome-mobile">Welcome to</h1>
                    <h1 className="frat-name-mobile">Yale AEPi</h1>
                    <h1 className="frat-name">Welcome to Yale AEPi</h1>
                    <div className="scroll-down">
                        <p>Got song reqs? Tell us</p>
                        <Link to="spotify-section"><img src={caret} className="caret" alt={"Down caret"} /></Link>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default Hero;