// https://www.freecodecamp.org/news/how-to-use-react-router-version-6/
import './App.css';
import React from "react"; // ?
import {
  Routes, Route
} from "react-router-dom";
import NavigationBar from './components/NavigationBar';
// import RecForm from './components/RecForm';
import Hero from './components/Hero';
import SpotifySearch from './components/SpotifySearch';
import DJView from './components/DJView';
import { GlobalStyle } from './components/styles';
import Home from './components/Home';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      {/* <NavigationBar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/fire" element={<RecForm />} /> */}
        {/* <Route path="/spotify" element={<SpotifySearch />} /> */}
        <Route path="/master" element={<DJView />} />
      </Routes>
    </div>
  );
}

export default App;
