import React from "react";
import Hero from '../Hero';
import SpotifySearch from '../SpotifySearch';
import "./Home.css";

const Home = () => {

    return(
        <div className="Home">
            <div className="extra-hero">
                <Hero />
            </div>
            <div className="extra-space" id="spotify-section">
                <SpotifySearch />
            </div>
        </div>
    );
    
};

export default Home;