// https://www.youtube.com/watch?v=1PWDxgqLmDA
import React from "react";
import "./SpotifySearch.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Container, InputGroup, FormControl, Button, Row, Card } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { StyledTrackListSearch } from '../styles';

import { initializeApp } from "firebase/app";
import {
  getFirestore, collection, addDoc, Timestamp
} from 'firebase/firestore'

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

///////////////////////////////////
  
initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
const db = getFirestore()
const colRef = collection(db, 'songRecs')

///////////////////////////////////

const SpotifySearch = () => {
    const [searchInput, setSearchInput] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [key, setKey] = useState(0); // for rerendering the disabled buttons
    const [songs, setSongs] = useState([]);

    useEffect(() => {
        var authParameters = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'grant_type=client_credentials&client_id=' + CLIENT_ID + '&client_secret=' + CLIENT_SECRET
        }
        fetch('https://accounts.spotify.com/api/token', authParameters) // implement error handling
            .then(result => result.json())
            .then(data => setAccessToken(data.access_token))
    }, [])

    async function searchSong() {
        console.log("searching for " + searchInput);

        var searchParameters = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        }
        var returnedTracks = await fetch('https://api.spotify.com/v1/search?q=' + searchInput + '&type=track&market=US&limit=20', searchParameters)
            .then(result => result.json())
            .then(data => { 
                console.log(data);
                setSongs(data.tracks.items);
            })

    }

    // async function searchArtist() {
    //     console.log("searching for " + searchInput);

    //     var searchParameters = {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + accessToken
    //         }
    //     }
    //     var artistID = await fetch('https://api.spotify.com/v1/search?q=' + searchInput + '&type=artist', searchParameters)
    //         .then(result => result.json())
    //         .then(data => { return data.artists.items[0].id })

    //     var returnedTracks = await fetch('https://api.spotify.com/v1/artists/' + artistID + '/top-tracks?market=US&limit=50', searchParameters)
    //         .then(result => result.json())
    //         .then(data => { 
    //             console.log(data); 
    //             setSongs(data.tracks);
    //         })

    // }

    async function sendToDJ(song, index) {
        console.log("sending to DJ: " + song.artists[0].name + "'s " + song.name);
        addDoc(colRef, {
            artist: song.artists[0].name,
            track: song.name,
            album: song.album.name,
            album_cover: song.album.images[2].url,
            created_at: Timestamp.now()
          })
        
        var element = document.getElementById(index); // this is wrong bc not using refs and not interacting w virtual DOM. fix?
        // Actually that might be the same issue that's caused by the querySelector in RecForm.js (which luckily I won't need soon anyways)
        element.classList.add("disabled");
    }



    return (
    <div>
        <Container>
            <InputGroup className="mb-3 inputbox" size="lg">
                <FormControl 
                    placeholder="Search Spotify" /*Search for a song or artist*/
                    type="input"
                    className="border border-dark"
                    onKeyDown={event => {
                        if (event.key === "Enter" && searchInput !== "") {
                            searchSong();
                            setKey(currentKey => currentKey+1)
                        }
                    }}
                    onChange={event => setSearchInput(event.target.value)}
                />
                <Button className="search-btn btn-spotify border border-dark" onClick={() => {
                    if (searchInput !== "") {
                        searchSong();
                        setKey(currentKey => currentKey+1)
                    }
                }}>
                    Search
                </Button>
                {/* <Button className="btn-spotify border border-dark" onClick={() => {
                    if (searchInput !== "") {
                        searchArtist();
                        setKey(currentKey => currentKey+1)
                    }
                }}>
                    Artist
                </Button> */}
            </InputGroup>
                
        </Container>
        {/* <div className = "container-md">
            <Row className="mx-2 row">
                {songs.map((song, index) => {  // Note that some albums will be repeated, since we can't tell apart explicit/non-explicit version
                    return (
                        <div className="card mb-3">
                            <div class="row g-0">
                                    <div class="col-md-4">
                                    <img src={song.album.images[0].url} className="img-fluid"/>
                                </div>
                                <div class="col-md-8">
                                    <div className="card-body">
                                        <h6 className="card-title">"{song.name}" - {song.artists[0].name}</h6>
                                        <Button onClick={() => {sendToDJ(song, index)}} className = "btn-secondary btn-sm" id={index}>
                                            Send to DJ
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    })
                }
            </Row>
        </div> */}

        <StyledTrackListSearch> {/*Could move key={key} into lower tag like div above Button, if would decrease reads*/}
            {songs.map((song, index) => {
                return(
                    <li className="track__item" key={index}>
                <div className="track__item__num">{index + 1}</div>
                <div key={key} className="track__item__title-group">
                    <Button onClick={() => {sendToDJ(song, index)}} 
                            className = "request-btn btn-spotify btn-sm" id={index}>
                         Request
                    </Button>
                    {song.album.images.length && song.album.images[2] && (
                        <div className="track__item__img">
                            <img src={song.album.images[2].url} alt={song.name} />
                        </div>
                    )}
                    <div className="track__item__name-artist">
                        <div className="track__item__name overflow-ellipsis">
                            {song.name}
                        </div>
                        <div className="track__item__artist overflow-ellipsis">
                            {song.artists.map((artist, index) => (
                                <span key={index}>
                                    {artist.name}{index !== song.artists.length - 1 && ', '}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="track__item__album overflow-ellipsis">
                    {song.album.name}
                </div>
            </li>
                )
            }
            )}
        </StyledTrackListSearch>
    
    </div>
    );
};

export default SpotifySearch;