import styled from 'styled-components/macro';

const StyledTrackList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  .track__item {
    display: grid;
    grid-template-columns: 1fr auto; /* Space for time and delete button */
    align-items: center;
    padding: var(--spacing-xs);
    color: var(--light-grey);
    background-color: var(--black);
    font-size: var(--fz-sm);

    &:hover,
    &:focus {
      background-color: var(--dark-grey);
    }
  }

  .track__item__title-group {
    display: flex;
    align-items: center;
  }

  .track__item__img {
    margin-right: var(--spacing-sm);
    width: 40px;
    height: 40px;
    background-color: var(--dark-grey);
  }

  .track__item__name-artist {
    display: flex;
    flex-direction: column;
  }

  .track__item__name {
    color: var(--white);
    font-size: var(--fz-md);
  }

  .track__item__time {
    color: var(--light-grey);
    font-size: var(--fz-sm);
    margin-right: var(--spacing-sm);
  }

  .delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: darkred;
    }
  }
`;

export default StyledTrackList;
